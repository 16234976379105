import React from 'react';
import { Link } from 'react-router-dom';
// import { isMobile } from 'react-device-detect';

import Mapa from '../components/Mapa';
import VoteForm from '../components/VoteForm';
import Regulamento from '../components/Regulamento';

import { useGlobal } from '../contexts/global';

import '../assets/scss/Vote.scss';

import iconClose from '../assets/images/icon_close.png';

const Vote = () => {
  const { showRegulamento } = useGlobal();

  return (
    <div className="in-vote">
      <div className="in-vote-close">
        <Link to="/">
          <img src={iconClose} alt={iconClose} />
          <span>Fechar</span>
        </Link>
      </div>
      <div className="in-vote-grid">
        {showRegulamento && <Regulamento />}
        <VoteForm />
        <Mapa />
      </div>
    </div>
  );
};

export default Vote;
