import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useGlobal } from '../contexts/global';
import { signInSSO, votacao } from '../services/Votacao';

const LoginFacebook = () => {
  const location = useLocation();
  const history = useHistory();
  const { setSuccess, setError } = useGlobal();

  const vote = (token) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute('process.env.REACT_APP_KEY_RECAPTCHA', {
          action: 'submit'
        })
        .then(async (gtoken) => {
          try {
            if (localStorage.getItem('vote_data')) {
              const data = JSON.parse(localStorage.getItem('vote_data'));
              data.token_grecaptcha = gtoken;
              await votacao(data, token);
              setSuccess(true);
              history.push('/');
            }
          } catch (error) {
            if (error.response.status === 422) {
              setError(true);
              history.push('/');
            } else {
              history.push('/');
            }
          }
        });
    });
  };

  const login = async () => {
    try {
      const resp = await signInSSO(location.search, 'facebook');
      vote(resp.access_token);
    } catch (error) {
      history.push('/');
    }
  };

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.id = id;
        script.onload = () => {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      'recaptcha-key',
      `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_KEY_RECAPTCHA}`,
      () => {}
    );
    login();
  }, []);

  return <></>;
};

export default LoginFacebook;
