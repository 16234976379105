import React from 'react';
import { BrowserRouter, Link } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';

import { GlobalProvider } from './contexts/global';
import Routes from './routes';

import './App.scss';

function App() {
  return (
    <BrowserRouter>
      <GlobalProvider>
        <Routes />
        <CookieConsent
          buttonText="Prosseguir"
          style={{ background: '#7B3602' }}
          buttonStyle={{
            color: '#000000',
            background: '#FF9300',
            fontSize: '1rem',
            fontFamily: 'Nunito',
            fontWeight: 500
          }}
        >
          <p>
            Utilizamos cookies e outras tecnologias para lhe oferecer uma
            experiência de navegação melhor, analisar o tráfego do site e
            personalizar o conteúdo, de acordo com a nossa{' '}
            <Link to="/lgpd" style={{ textDecoration: 'underline' }}>
              Política de Privacidade
            </Link>{' '}
            e{' '}
            <Link to="/termos-de-uso" style={{ textDecoration: 'underline' }}>
              Termos de Uso
            </Link>
            . Ao continuar navegando, você concorda com estas condições.
          </p>
        </CookieConsent>
      </GlobalProvider>
    </BrowserRouter>
  );
}

export default App;
