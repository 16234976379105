import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from '../pages/Home';
import Vote from '../pages/Vote';
import VerifyEmail from '../pages/VerifyEmail';
import LoginGoogle from '../pages/LoginGoogle';
import LoginFacebook from '../pages/LoginFacebook';
import NotFound from '../pages/NotFound';

const Routes = () => {
  return (
    <Switch>
      <Route path="/" component={Home} exact />
      <Route path="/termos-de-uso" component={Home} exact />
      <Route path="/lgpd" component={Home} exact />
      <Route path="/padaria/:slug" component={Home} exact />
      <Route path="/votacao/:tema" component={Vote} exact />
      <Route path="/email/verificar/:hash" component={VerifyEmail} exact />
      <Route path="/login/google/callback" component={LoginGoogle} exact />
      <Route path="/login/facebook/callback" component={LoginFacebook} exact />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Routes;
