import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import '../assets/scss/components/LGPD.scss';

const LGPD = () => {
  const history = useHistory();
  const modal = useRef();

  const back = (e) => {
    e.stopPropagation();
    history.push('/');
  };

  const handleModal = (e) => {
    if (modal.current !== null && modal.current !== undefined) {
      if (!modal.current.contains(e.target)) {
        back(e);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleModal);
    return () => {
      document.removeEventListener('mousedown', handleModal);
    };
  }, []);

  return (
    <div className="wrapper-lgpd">
      <div className="modal" ref={modal}>
        <div className="container">
          <h1 className="title">
            POLÍTICA DE PRIVACIDADE E TRATAMENTO DE DADOS PESSOAIS DO
            PADOCARIASP
          </h1>
          <div className="content">
            <p>
              A presente Política de Privacidade e Tratamento de Dados Pessoais
              (“DADOS”) tem por finalidade demonstrar o compromisso do
              PADOCARIASP com a privacidade e o tratamento dos dados pessoais de
              seus USUÁRIOS.
            </p>
            <br />
            <p>
              Como condição de acesso ao PADOCARIASP, você (“USUÁRIO”) declara
              que fez a leitura completa e atenta, estando plenamente ciente da
              presente Política de Privacidade e Tratamento de Dados Pessoais,
              conferindo, expressamente, sua livre, inequívoca e informada
              concordância com os termos aqui estipulados, autorizando a
              obtenção dos dados pessoais e informações aqui mencionados, bem
              como sua utilização para as finalidades abaixo especificadas. Caso
              não esteja de acordo com estas diretivas, o USUÁRIO deve
              descontinuar o seu acesso no PADOCARIASP.
            </p>
            <br />
            <p>
              <strong>
                1. COLETA E USO DE DADOS PESSOAIS E REGISTRO DE ATIVIDADES
              </strong>
            </p>
            <br />
            <p>
              1.1. Os DADOS são coletados a partir do consentimento do USUÁRIO.
            </p>
            <br />
            <p>
              1.2. A relação de DADOS coletados e as respectivas finalidades
              constam no Anexo I.
            </p>
            <br />
            <p>
              1.3. Outros DADOS que não estão descritos no Anexo I podem ser
              coletados de acordo com a natureza específica do produto ou
              serviço em questão, nos termos de documento específico que deve
              ser considerado em conjunto com esta Política de Privacidade e
              Tratamento de Dados Pessoais.
            </p>
            <br />
            <p>
              1.4. O PADOCARIASP não é responsável pela precisão, veracidade ou
              atualidade das informações prestadas pelo USUÁRIO, sendo de
              responsabilidade do USUÁRIO prestá-las com exatidão ou
              atualizá-las sempre que for o caso.
            </p>
            <br />
            <p>
              1.5. O PADOCARIASP pode realizar atualização e enriquecimento dos
              DADOS.
            </p>
            <br />
            <p>
              1.6. O PADOCARIASP garante ao USUÁRIO o direito de correção de
              DADOS incompletos, inexatos ou desatualizados.
            </p>
            <br />
            <p>
              1.7. Ao utilizar os ambientes digitais do PADOCARIASP, o USUÁRIO
              pode ser conduzido, via link, a outros portais ou plataformas
              independentes que podem coletar as informações do USUÁRIO e ter
              sua própria Política de Privacidade Política de Privacidade e
              Tratamento de Dados Pessoais.
            </p>
            <br />
            <p>
              1.8. Cabe ao USUÁRIO ler a Política de Privacidade e Tratamento de
              Dados Pessoais de tais ambientes digitais fora do ambiente do
              PADOCARIASP, sendo de responsabilidade do USUÁRIO aceitá-la ou
              rejeitá-la.
            </p>
            <br />
            <p>
              1.8.1. O PADOCARIASP não é responsável pela Política de
              Privacidade e Tratamento de Dados Pessoais nem pelo conteúdo de
              quaisquer sites, conteúdos ou serviços fora do ambiente do
              PADOCARIASP, ainda que a ele vinculados por meio de links.
            </p>
            <br />
            <p>
              1.9. Os DADOS citados no Anexo I poderão ser compartilhados com
              marcas e/ou Empresas parceiras e apoiadoras do PADOCARIASP, com a
              finalidade de uso dos Dados restrita ao envio de promoções e
              desenvolvimento de produtos e serviços em favor do USUÁRIO (e não
              para a monetização do DADO).
            </p>
            <br />
            <p>
              1.9.1. Ao consentir com o compartilhamento dos seus DADOS, o
              USUÁRIO compartilhará com as empresas parceiras e apoiadoras do
              PADOCARIASP as seguintes informações: Nome e e-mail.
            </p>
            <br />
            <p>
              1.9.2. O USUÁRIO reconhece e aceita que ao conceder o seu
              consentimento o faz de forma livre e por sua própria vontade,
              risco e responsabilidade. Recomendamos que, previamente à
              concessão do seu consentimento e escolha das Empresas, o USUÁRIO
              verifique os Termos de Uso e Política de Privacidade de cada
              Empresa, já que o PADOCARIASP não se responsabiliza pelo uso que
              as Empresas farão com os seus dados.
            </p>
            <br />
            <p>
              1.9.3. Ainda que o PADOCARIASP deixe claro para tais Empresas que
              a finalidade de uso dos DADOS deverá estar restrita ao envio de
              promoções e desenvolvimento de produtos e serviços em favor do
              USUÁRIO (e não para a monetização do DADO), caberá ao PADOCARIASP
              apenas o compartilhamento dos DADOS, desde que autorizado pelo
              USUÁRIO, não sendo o PADOCARIASP responsável por quaisquer perdas
              e danos e/ou prejuízos que o USUÁRIO possa ter que arcar devido à
              utilização e Tratamento de seus Dados Pessoais por tais Empresas.
            </p>
            <br />
            <p>
              1.10. O consentimento fornecido pelo USUÁRIO é coletado de forma
              individual, clara, específica, legítima e informado ao titular.
            </p>
            <br />
            <p>
              1.11. O USUÁRIO pode alterar suas concessões de consentimento,
              conceder novas permissões ou retirar seu consentimento para as
              emissões atuais por meio do Canal de Atendimento à LGPD do
              PADOCARIASP, disponível no e-mail{' '}
              <a
                href="mailto:padocariasp@gmail.com"
                style={{ color: '#40b2f5', textDecoration: 'underline' }}
              >
                padocariasp@gmail.com
              </a>{' '}
              e no endereço eletrônico{' '}
              <a
                href="https://padocariasp.com.br/lgpd"
                target="_blank"
                style={{ color: '#40b2f5', textDecoration: 'underline' }}
                rel="noreferrer"
              >
                https://padocariasp.com.br/lgpd
              </a>
              .
            </p>
            <br />
            <p>
              1.12. O USUÁRIO pode entrar em contato diretamente com o
              PADOCARIASP por meio do e-mail{' '}
              <a
                href="mailto:padocariasp@gmail.com"
                style={{ color: '#40b2f5', textDecoration: 'underline' }}
              >
                padocariasp@gmail.com
              </a>
              , do instagram{' '}
              <a
                href="https://instagram.com/padocariasp"
                target="_blank"
                style={{ color: '#40b2f5', textDecoration: 'underline' }}
                rel="noreferrer"
              >
                @padocariasp
              </a>{' '}
              e do site{' '}
              <a
                href="https://padocariasp.com.br/lgpd"
                target="_blank"
                style={{ color: '#40b2f5', textDecoration: 'underline' }}
                rel="noreferrer"
              >
                https://padocariasp.com.br/lgpd
              </a>
              . Os dados coletados e as atividades registradas também podem ser
              compartilhados:
            </p>
            <br />
            <p>
              a) Com autoridades judiciais, administrativas ou governamentais
              competentes, nos casos de requerimento, requisição ou ordem
              judicial;
            </p>
            <br />
            <p>
              b) Com os órgãos de controle que fiscalizam o PADOCARIASP, quando
              solicitado.
            </p>
            <br />
            <p>
              1.13. A base de dados, formada por meio da coleta de dados
              pessoais, é de propriedade e responsabilidade do PADOCARIASP. Seu
              uso, acesso e compartilhamento, quando necessários, serão feitos
              dentro dos limites e propósitos das atividades institucionais do
              PADOCARIASP e descritos no Anexo I desta Política de Privacidade e
              em Termos de Uso específicos, quando existentes.
            </p>
            <br />
            <p>
              1.13.1. O USUÁRIO é corresponsável pelo sigilo e pela
              confidencialidade de seus DADOS.
            </p>
            <br />
            <p>
              1.13.2. O compartilhamento de senhas ou DADOS de acesso viola esta
              Política de Privacidade e Tratamento de Dados Pessoais e os Termos
              de Uso do PADOCARIASP.
            </p>
            <br />
            <p>
              1.14. Internamente, os DADOS dos USUÁRIOS são acessados somente
              por profissionais devidamente autorizados pelo PADOCARIASP, sendo
              eles titulares, empregados, prestadores de serviços e parceiros.
            </p>
            <br />
            <p>
              1.15. O PADOCARIASP respeita os princípios de licitude,
              finalidade, adequação, proporcionalidade, necessidade, livre
              acesso, qualidade dos dados, transparência, segurança, prevenção,
              não discriminação, responsabilização, prestação de contas,
              subsidiariedade e limitação de armazenamento, além de firmar o
              compromisso de confidencialidade e preservação da privacidade nos
              termos desta Política de Privacidade e Tratamento de Dados
              Pessoais com os USUÁRIOS.
            </p>
            <br />
            <p>
              <strong>2. ARMAZENAMENTO DOS DADOS E REGISTROS </strong>
            </p>
            <br />
            <p>
              2.1. Os dados pessoais coletados e os registros de atividades
              devem ser armazenados em ambiente seguro e controlado pelo prazo
              mínimo estipulado, conforme a tabela abaixo:
            </p>
            <br />
            <p>DADOS PESSOAIS: Dados cadastrais</p>
            <br />
            <p>PRAZO DE ARMAZENAMENTO: 10 anos após o término da relação</p>
            <br />
            <p>
              FUNDAMENTO LEGAL: Arts. 12 e 34 do Código de Defesa do Consumidor
              (Lei nº 8.078/90) e art. 70 da Constituição Federal.
            </p>
            <br />
            <p>DADOS PESSOAIS: Dados de identificação digital</p>
            <br />
            <p>PRAZO DE ARMAZENAMENTO: 6 meses do último acesso</p>
            <br />
            <p>
              FUNDAMENTO LEGAL: Art. 15 do Marco Civil da Internet (Lei nº
              12.965/14)
            </p>
            <br />
            <p>
              2.2. Os DADOS podem ser excluídos antes desse prazo no caso de
              solicitação do USUÁRIO. Os dados podem ser mantidos por período
              superior ao previsto neste prazo, por motivo previsto em lei, por
              decisão judicial, para fins de prestação de contas aos órgãos de
              controle ou por outros interesses legítimos do PADOCARIASP,
              devidamente especificados e informados ao titular de dados. Ao fim
              do prazo e da necessidade legal, os dados devem ser excluídos com
              uso de métodos de descarte seguro, ou utilizados de forma
              anonimizada para fins estatísticos.
            </p>
            <br />
            <p>
              2.3. Os dados coletados devem ser armazenados em servidores
              próprios ou em provedores de serviços que atendam aos controles de
              proteção de dados pessoais aplicáveis.
            </p>
            <br />
            <p>
              <strong>3. ATENDIMENTO AOS DIREITOS DO USUÁRIO </strong>
            </p>
            <br />
            <p>
              3.1. O USUÁRIO pode solicitar a confirmação da existência de
              tratamento; o acesso aos seus dados pessoais; ou a correção de
              seus DADOS, por meio do Canal de Atendimento a LGPD do PADOCARIA,
              por meio do instagram{' '}
              <a
                href="https://instagram.com/padocariasp"
                target="_blank"
                style={{ color: '#40b2f5', textDecoration: 'underline' }}
                rel="noreferrer"
              >
                @padocariasp
              </a>
            </p>
            <br />
            <p>
              3.2. Pelo Canal de Atendimento à LGPD, o USUÁRIO pode também:
              <br />
              (i) requerer o bloqueio do uso de seus DADOS;
            </p>
            <br />
            <p>
              (ii) manifestar oposição ao tratamento de seus DADOS realizado com
              fundamento em uma das hipóteses de dispensa de consentimento;
            </p>
            <br />
            <p>
              (iii) solicitar a eliminação de seus DADOS coletados e registrados
              pelo PADOCARIASP ao término de sua finalidade de uso;
              <br />
              (iv) solicitar a revisão de decisões automatizadas;
            </p>
            <br />
            <p>(v) solicitar portabilidade dos DADOS;</p>
            <br />
            <p>
              (vi) solicitar informações sobre com quem foram compartilhados os
              DADOS;
            </p>
            <br />
            <p>
              (vii) informações sobre o impacto da decisão de não consentir ou
              revogar o consentimento.
            </p>
            <br />
            <p>
              3.3. Mesmo que o USUÁRIO não conceda ou solicite a revogação do
              consentimento para as finalidades relacionadas ao envio de
              informações, o PADOCARIASP poderá enviar informações transacionais
              relativas à prestação dos serviços.
            </p>
            <br />
            <p>
              3.4. Para fins de auditoria, segurança, controle de fraudes e
              preservação de direitos, o PADOCARIASP pode permanecer com o
              histórico de registro e os DADOS dos USUÁRIOS por prazo maior nas
              hipóteses que a lei ou norma regulatória assim estabelecer, para
              realização de prestação de contas aos órgãos de controle, ou para
              preservação de direitos do PADOCARIASP ou do titular de dados.
            </p>
            <br />
            <p>ou do titular de dados.</p>
            <br />
            <p>
              <strong>4. DISPOSIÇÕES GERAIS </strong>
            </p>
            <br />
            <p>
              4.1. O PADOCARIASP reserva a si o direito de alterar a Política de
              Privacidade e Tratamento de Dados Pessoais a qualquer momento,
              conforme a finalidade ou a necessidade, como para adequação e
              conformidade com disposição de lei ou norma que tenha força
              jurídica equivalente, cabendo ao USUÁRIO verificar a versão
              atualizada sempre que efetuar o acesso aos sistemas do
              PADOCARIASP.
            </p>
            <br />
            <p>
              4.2. Em caso de atualizações neste documento e que demandem nova
              coleta de consentimento, o PADOCARIASP deve notificar o USUÁRIO
              pelos seus meios capazes de realizar nova coleta de consentimento
              ou pelos meios de contato por ele fornecidos.
            </p>
            <br />
            <p>
              4.3. Em caso de qualquer dúvida com relação às disposições
              constantes desta Política de Privacidade e Tratamento de Dados
              Pessoais, o USUÁRIO pode entrar em contato com o PADOCARIASP por
              meio do instagram{' '}
              <a
                href="https://instagram.com/padocariasp"
                target="_blank"
                style={{ color: '#40b2f5', textDecoration: 'underline' }}
                rel="noreferrer"
              >
                @padocariasp
              </a>
              .
            </p>
            <br />
            <p>
              4.4. Caso empresas terceirizadas realizem o tratamento de
              quaisquer DADOS coletados pelo PADOCARIASP, devem respeitar as
              condições aqui estipuladas e as normas de Segurança da Informação
              do PADOCARIASP, bem como Políticas e procedimentos adicionais.
            </p>
            <br />
            <p>
              4.5. O USUÁRIO reconhece que toda comunicação realizada por e-mail
              (aos endereços informados no seu cadastro), aplicativos de
              comunicação instantânea, rede social ou qualquer outra forma
              física, virtual e digital também são válidas como prova
              documental, sendo eficazes e suficientes para a divulgação de
              qualquer assunto que se refira aos serviços prestados pelo
              PADOCARIASP, bem como às condições de sua prestação ou a qualquer
              outro assunto nele abordado, ressalvadas as disposições
              expressamente diversas previstas nesta Política de Privacidade e
              Tratamento de Dados Pessoais.
            </p>
            <br />
            <p>
              4.6. O PADOCARIASP utiliza cookies e tecnologias similares para
              avaliar e compreender o perfil e o comportamento dos USUÁRIOS que
              visitam ou acessam as páginas, com a finalidade de personalizar a
              experiência de navegação do USUÁRIO ao promover e divulgar
              produtos e serviços, além de realizar prevenção a fraudes.
            </p>
            <br />
            <p>
              4.7. O PADOCARIA utiliza cookies classificados em três categorias:
              Essencial, Desempenho (Análise e Personalização) e Publicidade.
            </p>
            <br />
            <p>
              4.7.1. Essencial: Necessários para o funcionamento do site. Eles
              permitem a navegação pelo USUÁRIO em nossos sites e o uso dos seus
              serviços e recursos (por exemplo, cookies de segurança para
              autenticar USUÁRIOS, evitar a utilização fraudulenta de
              credenciais de login e proteger os dados do USUÁRIO de terceiros
              não autorizados).
            </p>
            <br />
            <p>
              4.7.2. Desempenho: Coletamos informações de forma anônima que
              permitem determinar informações suficientes para realizar análises
              de navegação, como por exemplo, o número de visitantes de uma
              página, como o USUÁRIO chegou ao site e as páginas acessadas.
            </p>
            <br />
            <p>
              4.7.3. Publicidade: utilizamos alguns cookies com o fim
              publicitário. Isto se faz para entregar anúncios e fazer campanhas
              publicitárias de acordo com um determinado público. Através desses
              cookies é possível entregar anúncios de acordo com o seu perfil de
              consumo no site.
            </p>
            <br />
            <p>
              4.8. É necessária a permissão do USUÁRIO para aceitar e gerenciar
              as preferências no uso dos cookies, antes de iniciada a sessão,
              para que sejam coletados.
            </p>
            <br />
            <p>
              4.9. Utilização de cookies de terceiros: Prestadores de serviços
              de tecnologia poderão utilizar seus próprios cookies nos serviços,
              com a nossa autorização, para prestação de serviços ao
              PADOCARIASP. Tais cookies coletarão os dados do USUÁRIO nas nossas
              propriedades para as finalidades previstas nesta Política.
            </p>
            <br />
            <p>
              <strong>5. LEI APLICÁVEL E JURISDIÇÃO </strong>
            </p>
            <br />
            <p>
              A presente Política de Privacidade e Tratamento de Dados Pessoais
              deve ser regida e interpretada segundo a legislação brasileira, no
              idioma português, sendo eleito o Foro Judiciário de São Paulo para
              dirimir qualquer litígio ou controvérsia envolvendo o presente
              documento, salvo ressalva específica de competência pessoal,
              territorial ou funcional pela legislação aplicável.
            </p>
            <br />
            <p>
              <strong>6. GLOSSÁRIO </strong>
            </p>
            <br />
            <p>
              6.1. Para os fins deste documento, devem se considerar as
              seguintes definições:
            </p>
            <br />
            <p>
              Cookies: Pequenos arquivos enviados pelo PADOCARIASP, gravados nos
              dispositivos dos USUÁRIOS, que armazenam as preferências e outras
              informações, com a finalidade de personalizar a navegação dos
              USUÁRIOS no SITE, de acordo com o seu perfil.
            </p>
            <br />
            <p>
              IP: Abreviatura de Internet Protocol. É o conjunto alfanumérico
              que identifica os dispositivos dos USUÁRIOS na Internet.
            </p>
            <br />
            <p>
              Session ID: Credencial de um USUÁRIO necessária para acessar o
              PADOCARIASP e utilizar as suas funcionalidades.
            </p>
            <br />
            <p>
              USUÁRIO: Pessoa natural que acessa e/ou utiliza as funcionalidades
              do PADOCARIASP.
            </p>
            <br />
            <p>
              <strong>
                7. ANEXO I - LISTA DE DADOS PESSOAIS E FINALIDADES DE USO TIPOS
                DE DADOS
              </strong>
            </p>
            <br />
            <p>(I)</p>
            <br />
            <p>
              TIPO DE DADOS:
              <br />
              Cadastrais
            </p>
            <br />
            <p>
              DADOS PESSOAIS:
              <br />
              Nome completo, CPF, email, telefone, perfil no Facebook.
            </p>
            <br />
            <p>FINALIDADE DE USO DOS DADOS:</p>
            <br />
            <p>- Identificar o USUÁRIO;</p>
            <br />
            <p>
              - Prover atendimento personalizado;
              <br />
              - Cumprir as obrigações decorrentes do uso das ações, dos produtos
              e serviços do PADOCARIASP;
              <br />
              - Informar sobre novidades, funcionalidades, conteúdos, notícias e
              demais eventos relevantes para a manutenção do relacionamento com
              o USUÁRIO;
              <br />
              - Promover e divulgar ações, produtos e serviços do PADOCARIASP;
              <br />
              - Responder a solicitações e pedidos de informações do USUÁRIO;
              <br />
              - Realizar pesquisas do interesse do PADOCARIASP;
              <br />
              - Permitir acesso às áreas restritas dos ambientes digitais do
              PADOCARIASP;
              <br />
              - Prestar contas para Órgãos de controle na forma da lei;
              <br />- Personalizar o ambiente dos clientes em sua navegação nos
              ambientes digitais do PADOCARIASP.
            </p>
            <br />
            <p>(II)</p>
            <br />
            <p>TIPO DE DADOS:</p>
            <br />
            <p>Dados de identificação digital</p>
            <br />
            <p>DADOS PESSOAIS:</p>
            <br />
            <p>- Endereço IP e Porta Lógica de Origem;</p>
            <br />
            <p>
              - Registros de interações com os ambientes digitais;
              <br />- Telas acessadas;
            </p>
            <br />
            <p>
              - Dispositivo (versão do sistema operacional, Geolocalização e
              outros aplicativos instalados, se necessário);
            </p>
            <br />
            <p>
              - Session ID;
              <br />- Cookies.
            </p>
            <br />
            <p>FINALIDADE DE USO DOS DADOS:</p>
            <br />
            <p>
              - Identificar o USUÁRIO
              <br />
              - Administrar, creditar e enviar serviços adquiridos por meio dos
              ambientes digitais;
              <br />
              - Cumprir as obrigações decorrentes do uso dos serviços;
              <br />
              - Promover e divulgar produtos e serviços do PADOCARIASP e de
              parceiros;
              <br />
              - Realizar prevenção a fraudes, personalizar a experiência de
              navegação nos canais, por meio de georreferenciamento;
              <br />
              <br />
            </p>
            <br />
            <p>(III)</p>
            <br />
            <p>TIPO DE DADOS:</p>
            <br />
            <p>Dados anonimizados</p>
            <br />
            <p>FINALIDADE DE USO DOS DADOS:</p>
            <br />
            <p>- Elaborar análises e estudos estatísticos.</p>
            <br />
          </div>
          <div className="actions">
            <button type="button" onClick={back}>
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LGPD;
