import React from 'react';
import { Link } from 'react-router-dom';

import '../assets/scss/NotFound.scss';

import bgPadocaria from '../assets/images/bg_padocaria.png';

const NotFound = () => {
  return (
    <section id="in-nf">
      <div className="in-nf-bg">
        <img src={bgPadocaria} alt={bgPadocaria} />
      </div>
      <div className="in-nf-txt">
        <div className="in-nf-txt-ct">
          <div>
            <h1>404</h1>
            <p>Página não encontrada :/</p>
            <p>Desculpa. A página que você está procurando não existe.</p>
          </div>
          <div>
            <p>
              <Link to="/">voltar</Link>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
