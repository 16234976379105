import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { votacaoFase2 } from '../services/Votacao';

import { useGlobal } from '../contexts/global';

import iconGoogle from '../assets/images/icon_google.png';

import '../assets/scss/components/ModalVotacao.scss';

const ModalVotacao = () => {
  const modal = useRef();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { setShowModalVotacao, nomePadaria, idPadaria, zonaAtual } =
    useGlobal();

  const close = (e) => {
    e.stopPropagation();
    setShowModalVotacao(false);
  };

  const handleModal = (e) => {
    if (modal.current !== null && modal.current !== undefined) {
      if (!modal.current.contains(e.target)) {
        close(e);
      }
    }
  };

  const handleVotacao = () => {
    setIsLoading(true);
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_KEY_RECAPTCHA, {
          action: 'submit'
        })
        .then(async (token) => {
          const data = {
            padaria_id: idPadaria,
            email,
            token_grecaptcha: token
          };

          try {
            const resp = await votacaoFase2(data);
            const tab = {
              regiao_central: 'REGIÃO CENTRAL',
              zona_leste: 'ZONA LESTE',
              zona_norte: 'ZONA NORTE',
              zona_oeste: 'ZONA OESTE',
              zona_sul: 'ZONA SUL',
              zp_pao_na_chapa_com_catupiry: 'PÃO NA CHAPA COM CATUPIRY'
            };
            setShowModalVotacao(false);
            Swal.fire({
              icon: 'warning',
              text: resp.success,
              confirmButtonText: 'Ok'
            });

            const votosPadarias =
              JSON.parse(localStorage.getItem(`${email}_padarias`)) || [];

            if (!votosPadarias.includes(zonaAtual)) {
              votosPadarias.push(zonaAtual);
              localStorage.setItem(
                `${email}_padarias`,
                JSON.stringify(votosPadarias)
              );
            }

            const zonasRestantes = Object.values(tab).filter(
              (zona) => !votosPadarias.includes(zona)
            );

            let textRedirect = 'Obrigado por votar nesta padaria!';
            let buttonText = 'Voltar';

            if (zonasRestantes.length > 0 && zonasRestantes.length <= 6) {
              const proximaZona = zonasRestantes[0];
              buttonText = `Ir para ${proximaZona}`;
              textRedirect = `Obrigado por votar. Você ainda não votou na zona ${proximaZona}. Clique para votar!`;

              const proximaZonaKey = Object.keys(tab).find(
                (key) => tab[key] === proximaZona
              );

              if (proximaZonaKey) {
                window.scrollTo(0, 0);
                setTimeout(() => {
                  document.getElementById('padarias').scrollIntoView({
                    behavior: 'smooth'
                  });
                }, 500);

                setTimeout(() => {
                  const tabElement = document.querySelector(
                    `[data-tab-key="${proximaZonaKey}"]`
                  );

                  if (tabElement) {
                    tabElement.click();
                  }
                }, 500);
              }
            } else {
              textRedirect =
                'Obrigado por votar em todas as padarias! Sua participação é muito importante para nós.';
            }

            Swal.fire({
              icon: 'info',
              text: textRedirect,
              confirmButtonText: buttonText
            }).then(() => {
              setIsLoading(false);
            });
          } catch (error) {
            setShowModalVotacao(false);
            if (error.response.status === 422) {
              Swal.fire({
                timer: 2500,
                icon: 'error',
                showConfirmButton: false,
                text: 'Usuário já votou nessa categoria!'
              });
            } else {
              Swal.fire({
                timer: 3500,
                icon: 'error',
                showConfirmButton: false,
                text: 'Ops! Houve um erro, tente novamente mais tarde.'
              });
            }
            setIsLoading(false);
          }
        });
    });
  };

  const handleClickSSO = (social) => {
    const data = {
      padaria_id: idPadaria
    };

    localStorage.setItem('vote_data', JSON.stringify(data));
    localStorage.setItem('last_zone', JSON.stringify(zonaAtual));

    if (social === 'google') {
      window.location.href = `${process.env.REACT_APP_API_URL}auth/login/google`;
    } else if (social === 'facebook') {
      window.location.href = `${process.env.REACT_APP_API_URL}auth/login/facebook`;
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleModal);
    return () => {
      document.removeEventListener('mousedown', handleModal);
    };
  }, []);

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.id = id;
        script.onload = () => {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    loadScriptByURL(
      'recaptcha-key',
      `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_KEY_RECAPTCHA}`,
      () => {}
    );
  }, []);

  return (
    <div className="in-vote">
      <div className="in-vote-modal" ref={modal}>
        <div className="in-vote-modal-grid">
          <div className="in-vote-modal-grid-title">
            <p>Confirmar voto em:</p>
            <p>{nomePadaria}</p>
            <p>
              Para finalizar o voto precisamos fazer uma
              <br />
              confirmação por e-mail
            </p>
          </div>
          <div className="in-vote-modal-grid-email">
            <div className="in-vote-modal-grid-email-input">
              <input
                id="email"
                type="email"
                name="email"
                value={email}
                placeholder="Digite o seu e-mail"
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                disabled={email === '' || isLoading}
                type="button"
                onClick={handleVotacao}
              >
                {isLoading ? (
                  <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                ) : (
                  'Confirmar'
                )}
              </button>
            </div>
            <div className="in-vote-modal-grid-email-or">
              <div />
              <span>ou</span>
              <div />
            </div>
            <div className="in-vote-modal-grid-email-btn">
              <button type="button" onClick={() => handleClickSSO('google')}>
                <img src={iconGoogle} alt={iconGoogle} />
                <span>Acessar com o google</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalVotacao;
