/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-empty */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-shadow */
import React, { useEffect, useRef } from 'react';

const AdBanner = () => {
  const bannerRef = useRef(null);

  useEffect(() => {
    const img = document.createElement('img');
    img.src = 'data:image/png,ft';
    img.style.display = 'none';

    img.onerror = () => {
      const ftClick = '';
      window.ftExpTrack_8832505 = '';
      window.ftX = '';
      window.ftY = '';
      window.ftZ = '';
      window.ftOBA = 1;
      window.ftContent = '';
      window.ftCustom = '';
      window.ft1080x462_OOBclickTrack = '';
      window.ftRandom = Math.random() * 1000000;
      window.ftClick_8832505 = ftClick;

      if (typeof window.ft_referrer === 'undefined') {
        window.ft_referrer = (() => {
          let r = '';
          if (window === top) {
            r = window.location.href;
          } else {
            try {
              r = window.parent.location.href;
            } catch (e) {}
            r = r || document.referrer;
          }
          while (encodeURIComponent(r).length > 1000) {
            r = r.substring(0, r.length - 1);
          }
          return r;
        })();
      }

      const deployTag = () => {
        const ftTag = document.createElement('script');
        ftTag.id = 'ft_servedby_8832505';
        ftTag.src = `https://servedby.flashtalking.com/imp/1/252872;8832505;201;jsappend;PadocariaBR;bannerpadocariadsplysuperbannerstanddisplybsaybrdnanaiabna/?gdpr=1&gdpr_consent=BOEFEAyOEFEAyAHABDENAAAAwAAA&us_privacy=1---`;

        if (bannerRef.current) {
          bannerRef.current.appendChild(ftTag);
        }
      };

      deployTag();
    };

    if (bannerRef.current) {
      bannerRef.current.appendChild(img);
    }
  }, []);

  return (
    <div ref={bannerRef} className="ft_async_tag">
      <noscript>
        <a
          href="https://servedby.flashtalking.com/click/1/252872;8832505;0;209;0/?gdpr=1&gdpr_consent=BOEFEAyOEFEAyAHABDENAAAAwAAA&us_privacy=1---&ft_width=1080&ft_height=462&url=40088044"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            border="0"
            src="https://servedby.flashtalking.com/imp/1/252872;8832505;205;gif;PadocariaBR;bannerpadocariadsplysuperbannerstanddisplybsaybrdnanaiabna/?gdpr=1&gdpr_consent=BOEFEAyOEFEAyAHABDENAAAAwAAA&us_privacy=1---"
            alt="Ad banner"
          />
        </a>
      </noscript>
    </div>
  );
};

export default AdBanner;
