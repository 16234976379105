import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useGlobal } from '../contexts/global';
import { signInSSO, votacaoFase2 } from '../services/Votacao';

const LoginGoogle = () => {
  const location = useLocation();
  const history = useHistory();
  const { setSuccess, setError } = useGlobal();

  const vote = (token) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_KEY_RECAPTCHA, {
          action: 'submit'
        })
        .then(async (gtoken) => {
          try {
            if (localStorage.getItem('vote_data')) {
              const data = JSON.parse(localStorage.getItem('vote_data'));
              data.token_grecaptcha = gtoken;
              await votacaoFase2(data, token);

              const zonasDisponiveis = {
                regiao_central: 'REGIÃO CENTRAL',
                zona_leste: 'ZONA LESTE',
                zona_norte: 'ZONA NORTE',
                zona_oeste: 'ZONA OESTE',
                zona_sul: 'ZONA SUL',
                zp_pao_na_chapa_com_catupiry: 'PÃO NA CHAPA COM CATUPIRY'
              };

              const lastZone =
                JSON.parse(localStorage.getItem('last_zone')) || [];
              const votosPadarias =
                JSON.parse(localStorage.getItem('vote_data_zones')) || [];

              if (!votosPadarias.includes(lastZone)) {
                votosPadarias.push(lastZone);
                localStorage.setItem(
                  'vote_data_zones',
                  JSON.stringify(votosPadarias)
                );
              }

              const zonasRestantes = Object.values(zonasDisponiveis).filter(
                (zona) => !votosPadarias.includes(zona)
              );

              let mensagemFinal = 'Obrigado por votar nesta padaria!';
              let textoBotao = 'Voltar';
              let proximaZonaKey = null;

              if (zonasRestantes.length > 0) {
                const proximaZona = zonasRestantes[0];
                textoBotao = `Ir para ${proximaZona}`;
                mensagemFinal = `Obrigado por votar. Você ainda não votou na ${proximaZona}. Clique para votar!`;

                proximaZonaKey = Object.keys(zonasDisponiveis).find(
                  (key) => zonasDisponiveis[key] === proximaZona
                );
                localStorage.setItem('proxima_zona', proximaZonaKey);
              } else {
                mensagemFinal =
                  'Obrigado por votar em todas as padarias! Sua participação é muito importante para nós.';
              }

              Swal.fire({
                icon: 'info',
                text: mensagemFinal,
                confirmButtonText: textoBotao
              }).then(() => {
                setSuccess(true);
                history.push('/');
              });
            }
          } catch (error) {
            if (error.response.status === 422) {
              setError(true);
              history.push('/');
            } else {
              history.push('/');
            }
          }
        });
    });
  };

  const login = async () => {
    try {
      const resp = await signInSSO(location.search, 'google');
      vote(resp.access_token);
    } catch (error) {
      history.push('/');
    }
  };

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.id = id;
        script.onload = () => {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      'recaptcha-key',
      `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_KEY_RECAPTCHA}`,
      () => {}
    );
    login();
  }, []);

  return <></>;
};

export default LoginGoogle;
