import API from '../plugins/axios';

export const buscaLocais = (local) => {
  return new Promise((resolve, reject) => {
    API.get(`google-maps/buscar?endereco=${local}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`
      }
    })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const buscaPadarias = () => {
  return new Promise((resolve, reject) => {
    API.get('padarias', {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`
      }
    })
      .then((resp) => {
        resolve(resp.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const buscaPadariaSlug = (slug) => {
  return new Promise((resolve, reject) => {
    API.get(`padarias/${slug}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`
      }
    })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const buscaZonas = () => {
  return new Promise((resolve, reject) => {
    API.get('zonas', {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`
      }
    })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const buscaBairros = (zonaId) => {
  return new Promise((resolve, reject) => {
    API.get(`zonas/${zonaId}/bairros`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`
      }
    })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const votacao = (data, token = '') => {
  return new Promise((resolve, reject) => {
    API.post('votacao', data, {
      headers: {
        Authorization: `Bearer ${token || process.env.REACT_APP_API_TOKEN}`
      }
    })
      .then((resp) => {
        localStorage.removeItem('vote_data');
        resolve(resp.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const votacaoFase2 = (data, token = '') => {
  return new Promise((resolve, reject) => {
    API.post('votacao/fase2', data, {
      headers: {
        Authorization: `Bearer ${token || process.env.REACT_APP_API_TOKEN}`
      }
    })
      .then((resp) => {
        localStorage.removeItem('vote_data');
        resolve(resp.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const verificaEmail = (hash) => {
  return new Promise((resolve, reject) => {
    API.get(`email/verificar/${hash}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`
      }
    })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function signInSSO(searchString, provider) {
  return new Promise((resolve, reject) => {
    API.get(`auth/login/${provider}/callback${searchString}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`
      }
    })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
