import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useGlobal } from '../contexts/global';
import { verificaEmail } from '../services/Votacao';

const VerifyEmail = () => {
  const history = useHistory();
  const { hash } = useParams();
  const { setSuccess } = useGlobal();

  useEffect(() => {
    const sendHash = async () => {
      try {
        await verificaEmail(hash);
        setSuccess(true);
        history.push('/');
      } catch (error) {
        history.push('/');
      }
    };
    sendHash();
  }, []);

  return <></>;
};

export default VerifyEmail;
