import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import '../assets/scss/components/TermosDeUso.scss';

const TermosDeUso = () => {
  const history = useHistory();
  const modal = useRef();

  const back = (e) => {
    e.stopPropagation();
    history.push('/');
  };

  const handleModal = (e) => {
    if (modal.current !== null && modal.current !== undefined) {
      if (!modal.current.contains(e.target)) {
        back(e);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleModal);
    return () => {
      document.removeEventListener('mousedown', handleModal);
    };
  }, []);

  return (
    <div className="wrapper-termos">
      <div className="modal" ref={modal}>
        <div className="container">
          <h1 className="title">Termos de uso</h1>
          <div className="content">
            <h2>1. INTRODUÇÃO </h2>
            <p>
              Neste site, o PADOCARIASP apresenta um conteúdo que contribui para
              a valorização e o reconhecimento das padarias e de seus
              profissionais que ajudam a construir a convivência e a gastronomia
              da cidade de São Paulo. Atuando diretamente na valorização das
              padarias, o PadocariaSP também contribui para estimular a
              população a reconhecer e valorizar as padarias de cada bairro da
              cidade. O PadocariaSP foi idealizado e é promovido pela M.Pad
              Produções, Marketing e Conteúdo.
            </p>
            <br />

            <h2>2. ACESSO PÚBLICO E RESTRIÇÕES </h2>
            <p>
              Este site poderá ser acessado de forma gratuita em toda a sua
              extensão e em todo o seu conteúdo. O PADOCARIASP reserva-se o
              direito de manter alguns conteúdos restritos mediante cadastro.
              Para que o usuário tenha acesso a estes conteúdos, é necessário
              cadastrar-se, fornecer as informações solicitadas e concordar com
              os termos apresentados. Todos os dados inseridos neste site são
              elaborados pelo PADOCARIASP de forma direta, ou por seus
              prestadores de serviço e parceiros. Não é permitido ao usuário o
              acesso ao código fonte do site, seu banco de dados ou qualquer
              outro conjunto de informações internas. Quem o fizer, estará
              sujeito às penas previstas na legislação penal e cível regentes no
              País. Também não é permitido ao usuário qualquer técnica para
              alterar, modificar, falsear ou esconder o número do seu IP durante
              seu acesso ao site, bem como submeter conteúdos ao site que
              contiverem programas maliciosos. Caso haja descumprimento, seu
              cadastro e acesso poderão ser suspensos ou excluídos e medidas
              jurídicas cabíveis podem ser tomadas.{' '}
            </p>
            <br />

            <h2>3. DO CADASTRO PARA ACESSO, INCLUSIVE NAS ÁREAS RESTRITAS</h2>
            <p>
              Para participar do PadocariaSP e ter acesso às áreas restritas, o
              usuário é obrigado a cadastrar-se e concordar em inserir ali
              apenas informações absolutamente verdadeiras, exatas e atuais. O
              cadastro em desacordo com essas obrigações poderá ser excluído
              pelo PADOCARIASP, nos termos do Item 4. O usuário também poderá
              ter de criar uma senha. A confidencialidade desta senha é de sua
              inteira responsabilidade, bem como qualquer alteração ou
              movimentação em seu cadastro.{' '}
            </p>
            <br />
            <h2>4. EXCLUSÃO DO CADASTRO </h2>
            <p>
              O PADOCARIASP poderá suspender ou excluir, a qualquer tempo, as
              contas de acesso dos usuários que descumprirem o presente Termo de
              Uso, que transgredirem a lei ou procederem de forma não ética, sem
              a necessidade de aviso prévio ou justificativa. Essa decisão não
              gerará direito a nenhuma espécie de indenização ou ressarcimento,
              sob qualquer pretexto.{' '}
            </p>
            <br />

            <h2>5. DA PUBLICAÇÃO DE CONTEÚDOS NO SITE</h2>
            <p>
              Todo conteúdo a ser publicado no site será analisado e autorizado
              por uma equipe de redatores, editores e revisores do PADOCARIASP.
              Dessa forma, o PADOCARIASP tem responsabilidade pelos conteúdos
              publicados e responde por eventuais danos causados aos usuários. O
              PADOCARIASP tem o direito de suspender o acesso ou retirar do site
              qualquer conteúdo publicado que entenda inconveniente, sem prévia
              autorização ou justificativa.{' '}
            </p>
            <br />

            <h2>6. CONDIÇÕES PARA USO DOS CONTEÚDOS </h2>
            <p>
              Todo o conteúdo do site PADOCARIASP poderá servir para o uso
              unicamente pessoal do usuário, desde que citada a fonte. Está
              expressamente proibido alterar, plagiar, vender ou distribuir, com
              finalidade comercial, total ou parcialmente, qualquer conteúdo
              publicado no site sem a autorização prévia e formalizada pelo
              PADOCARIASP. Os conteúdos sem identificação do autor são de
              propriedade do PADOCARIASP e podem também ser utilizados das
              mesmas formas, desde que atendidos os mesmos requisitos. Caso o
              conteúdo seja autorizado para publicação em outros sites, estes
              deverão remeter ao conteúdo original por meio de hiperlink,
              citando-o como fonte da nova publicação. O usuário concorda com o
              fato de não ser possível ao PADOCARIASP averiguar se todos os
              materiais estão em conformidade com os termos da Lei n.9.610/98
              (Lei de Direitos Autorais), em especial os conteúdos fornecidos a
              título de colaboração, isentando-o, portanto, de quaisquer
              responsabilidades nesse sentido. O simples acesso ao site
              PADOCARIASP já é considerado uma declaração do usuário de que irá
              respeitar todos os direitos de propriedade intelectual que estão
              depositados ou registrados em nome do PADOCARIASP, bem como de
              terceiros e que, por alguma razão, estejam disponíveis no site.{' '}
            </p>
            <br />

            <h2>7. DAS RESPONSABILIDADES </h2>
            <p>
              • ATENDIMENTO ONLINE O atendimento online do PADOCARIASP,
              acessível por meio do instagram @padocariasp pode ser utilizado
              após o cadastramento prévio do usuário e é gratuito. Todas as
              informações e dúvidas esclarecidas neste canal têm caráter de mera
              orientação e o PADOCARIASP não se responsabiliza por qualquer
              consequência ou dano causado ao usuário proveniente desta
              orientação, sendo de responsabilidade exclusiva do usuário as
              ações realizadas após o seu atendimento. As orientações e opiniões
              fornecidas pelo atendente tomam por base as informações e dados
              inseridos pelo usuário em seu cadastro, bem como aquelas
              resultantes de qualquer tipo de interação. Dessa forma, partem do
              pressuposto de que esses dados são verdadeiros. Assim, qualquer
              incongruência entre a orientação e as informações prestadas pelo
              usuário resultante da inconsistência desses dados, é de total
              responsabilidade do usuário que as inseriu. Por outro lado, o
              PADOCARIASP obriga-se a primar pela qualidade das orientações,
              informações e opiniões que irá fornecer durante o atendimento ao
              usuário.{' '}
            </p>
            <br />

            <p>
              • SOBRE OS LINKS INDICADOS O PADOCARIASP não se responsabiliza
              pelos links a sites externos apontados em suas páginas. As
              informações neles contidas são de inteira responsabilidade de quem
              as produziu. A visita a qualquer desses sites é mera liberalidade
              do usuário.{' '}
            </p>
            <br />

            <h2>8. MODIFICAÇÃO DO CONTEÚDO PUBLICADO </h2>
            <p>
              O PADOCARIASP tem livre acesso para alterar ou atualizar qualquer
              informação do site periodicamente ou conforme a sua necessidade.
              Portanto, nenhum conteúdo poderá ser considerado definitivo. As
              informações constantes no site serão sempre associadas à
              conveniência do tempo em que forem publicadas.{' '}
            </p>
            <br />

            <h2>9. DA ALTERAÇÃO DO PRESENTE TERMO DE USO </h2>
            <p>
              O PADOCARIASP poderá alterar o Termo de Uso presente, sem prévio
              aviso e quando julgar necessário.{' '}
            </p>
            <br />

            <h2>10. FORO COMPETENTE </h2>
            <p>
              Os Termos e Condições de Uso aqui descritos são interpretados
              segundo a legislação brasileira. Fica eleito o Foro da Comarca de
              São Paulo, no estado de São Paulo, para dirimir qualquer litígio,
              questão ou dúvida superveniente, com expressa renúncia de qualquer
              outro, por mais privilegiado que seja.
            </p>
            <br />
          </div>
          <div className="actions">
            <button type="button" onClick={back}>
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermosDeUso;
