import React, { useEffect, useRef } from 'react';

import { useGlobal } from '../contexts/global';
import '../assets/scss/components/Regulamento.scss';

const Regulamento = () => {
  const modal = useRef();
  const { setShowRegulamento } = useGlobal();

  const back = (e) => {
    e.stopPropagation();
    setShowRegulamento(false);
  };

  const handleModal = (e) => {
    if (modal.current !== null && modal.current !== undefined) {
      if (!modal.current.contains(e.target)) {
        back(e);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleModal);
    return () => {
      document.removeEventListener('mousedown', handleModal);
    };
  }, []);

  return (
    <div className="wrapper-regulamento">
      <div className="modal" ref={modal}>
        <div className="container">
          <h1 className="title">Regulamento</h1>
          <div className="content">
            <p>CAPÍTULO I – DO PRÊMIO</p>
            <br />
            <p>
              Art. 1º – O Prêmio Padocaria-SP contribui para a valorização e o
              reconhecimento das padarias, de seus profissionais, de seus
              serviços e de seus produtos, que ajudam a construir a convivência
              e a gastronomia na cidade de São Paulo.
            </p>
            <br />
            <p>
              Art. 2º – Atuando diretamente na valorização das padarias, o
              Padocaria-SP também contribui para estimular a população a
              reconhecer e a valorizar as padarias de sua comunidade, de seu
              bairro e de sua cidade.
            </p>
            <br />
            <p>
              Art. 3º – O Prêmio Padocaria-SP foi idealizado e é promovido pela
              M.Pad Produções, Marketing e Conteúdo (razão social: Miguel Angelo
              Padberg Icassatti).
            </p>
            <br />
            <p>CAPÍTULO II – DA PARTICIPAÇÃO</p>
            <br />
            <p>
              Art. 1º – A Fase 1 do Padocaria-SP é aberta a votação do público
              consumidor, que pode indicar seus votos a quaisquer padarias
              estabelecidas dentro dos limites da cidade de São Paulo nas
              seguintes categorias: 1) Café; 2) Pãozinho; 3) Pão de fermentação
              natural; 4) Pão na chapa; 5) Pizza; 6) Serviço de frios; 7) Sonho;
              8) Time de chapeiros; 9) Padaria.
              <br />
            </p>
            <br />
            <p>
              Art. 2º - A Fase 2 do Padocaria-SP é aberta às cinco padarias com
              maior número de votos, na soma de todas as categorias, de cada uma
              das cinco regiões da cidade de São Paulo, de acordo com as faixas
              de CEP: Zonas Norte, Sul, Leste, Oeste e Centro.
            </p>
            {/* <br />
            <p>
              Art. 3º – Em caso de empate na Fase 1, será considerado como
              critério de desempate o número total de votos recebidos pela
              padaria em todas as categorias. Ao persistir o empate, o prêmio
              será dividido e entregue a ambas.
            </p>
            <br />
            <p>
              Art. 4º – Para a Fase 2 serão levadas 25 padarias com mais
              indicações na Fase 1, assim distribuídas e organizadas: as 5 com
              mais indicações localizadas na Zona Norte; as 5 com mais
              indicações localizadas na Zona Leste; as 5 com mais indicações
              localizadas na Zona Sul; as 5 com mais indicações localizadas na
              Zona Oeste; as 5 com mais indicações localizadas na região
              central.
            </p> */}
            <br />
            <p>CAPÍTULO III – DAS POSSIBILIDADES DE PREMIAÇÃO</p>
            <br />
            <p>
              Art. 1° – Na Fase 1, uma padaria poderá ser premiada em até três
              categorias e poderá sagrar-se vencedora em apenas uma categoria,
              ainda que receba o maior número de votos em duas ou mais
              categorias. Caso ela receba o maior número de votos em mais de uma
              categoria, ela será considerada, para fins de premiação, a
              vencedora apenas da categoria na qual a padaria tiver recebido o
              maior número de votos absolutos.
            </p>
            <br />
            <p>
              Art. 2º – A padaria poderá ser considerada a 2ª colocada apenas na
              categoria na qual recebeu o maior número de votos, exceto na
              categoria da qual foi a vencedora.
            </p>
            <br />
            <p>
              Art. 3º – A padaria poderá ser considerada a 3ª colocada apenas na
              categoria na qual recebeu o maior número de votos, exceto na
              categoria na qual foi a vencedora e na categoria na qual foi a 2ª
              colocada.
            </p>
            <br />
            <p>
              Art. 4º – Em caso de empate na Fase 1, será considerado como
              critério de desempate o número total de votos recebidos pela
              padaria em todas as categorias. Ao persistir o empate, o prêmio
              será dividido e entregue a ambas.
            </p>
            <br />
            <p>
              Art. 5º – Para a Fase 2 serão levadas 25 padarias com mais
              indicações na Fase 1, assim distribuídas e organizadas: as 5 com
              mais indicações localizadas na Zona Norte; as 5 com mais
              indicações localizadas na Zona Leste; as 5 com mais indicações
              localizadas na Zona Sul; as 5 com mais indicações localizadas na
              Zona Oeste; as 5 com mais indicações localizadas na região
              central.
              <br />
            </p>
            {/* <br />
            <p>
              Art. 6º – A Fase 1 começa às 10h do dia 29 de setembro de 2024 e
              se encerra às 23h59 do dia 15 de outubro de 2024. A Fase 2 começa
              às 10h do dia 30 de outubro e se encerra às 23h59 do dia 12 de
              novembro de 2024.
            </p>
            <br />
            <p>
              Art. 7º – Na Fase 2, haverá a participação do júri técnico,
              formado por profissionais de comunicação e de gastronomia
              convidados pela organização do PadocariaSP, cuja identidade será
              mantida em sigilo.
            </p>
            <br />
            <p>
              Art. 8º - Na Fase 2, a soma dos votos do júri técnico terá peso de
              50% na apuração do resultado.
            </p>
            <br />
            <p>
              Art. 5° – O PadocariaSP se compromete a manter a confidencialidade
              das indicações e votos de cada votante. As informações e os
              resultados a serem divulgados não estarão atrelados a nenhuma
              fonte específica e serão apresentados no conjunto da premiação.
            </p> */}
            <br />
            <p>CAPÍTULO III – DA INDICAÇÃO E DA VOTAÇÃO</p>
            <br />
            <p>
              Art. 1º – A premiação se dará por indicação e voto realizados em
              duas fases, única e exclusivamente por meio da plataforma digital{' '}
              <a
                href="https://www.padocariasp.com.br"
                target="_blank"
                rel="noreferrer"
                style={{ color: '#40b2f5', textDecoration: 'underline' }}
              >
                www.padocariasp.com.br
              </a>
            </p>
            <br />
            <p>
              Art. 2º – Na Fase 1, a seleção dos indicados em cada categoria se
              dará apenas pelo voto do público, que pode indicar apenas 1 (um)
              estabelecimento em cada uma das categorias.
            </p>
            <br />
            <p>
              Art. 3º – É permitido indicar a mesma Padaria em uma ou mais
              categorias assim como é permitido indicar um padaria diferente
              para cada categoria.
            </p>
            <br />
            <p>Art 4º – É permitido voto em branco.</p>
            <br />
            <p>
              Art. 5º – Cada pessoa pode votar somente uma vez em cada uma das
              fases, mediante validação prévia de cadastro. Serão considerados
              válidos as indicações e os votos feitos no período de: 12 a 29 de
              setembro de 2024 (Fase 1); 11 a 27 de outubro de 2024 (Fase 2).
            </p>
            <br />
            <p>
              Art. 6º – A Fase 1 começa às 10h do dia 12 de setembro de 2024 e
              se encerra às 23h59 do dia 29 de setembro de 2024. A Fase 2 começa
              às 10h do dia 11 de outubro e se encerra às 23h59 do dia 27 de
              outubro de 2024.
            </p>
            <br />
            <p>
              Art. 7º – Na Fase 2, haverá a participação do júri técnico,
              formado por profissionais de comunicação e de gastronomia
              convidados pela organização do Padocaria-SP, cuja identidade será
              mantida em sigilo.
            </p>
            <br />
            <p>
              Art. 8º - Na Fase 2, a soma dos votos do júri técnico terá peso de
              50% na apuração do resultado.
            </p>
            <br />
            <p>
              Art. 9° – O Padocaria-SP se compromete a manter a
              confidencialidade das indicações e votos de cada votante. As
              informações e os resultados a serem divulgados não estarão
              atrelados a nenhuma fonte específica e serão apresentados no
              conjunto da premiação.
            </p>
            <br />
            <p>
              Art. 10º – Na Fase 2, o Padocaria-SP reserva-se o direito de
              desqualificar uma padaria finalista caso ela não instale o enxoval
              promocional do concurso em seu estabelecimento (banner, móbiles,
              display, sacolas de pão, saco de pão e materiais similares).
            </p>
            <br />
            <p>
              Art. 11º - Serão consideradas padarias “Hors Concours” aquelas que
              foram campeãs de uma mesma categoria em três edições do
              Padocaria-SP. Os votos a elas conferidos serão desconsiderados na
              presente edição, para aquela respectiva categoria.
            </p>
            <br />
            <p>CAPÍTULO IV – DOS RESULTADOS</p>
            <br />
            <p>
              Art. 1° – Serão concedidos prêmios (troféus e/ou diplomas) aos
              vencedores de cada categoria. Na Fase 1: 1) Café; 2) Pãozinho; 3)
              Pão de Fermentação Natural; 4) Pão na chapa; 5) Pizza; 6) Serviço
              de frios; 7) Sonho; 8) Time de chapeiros; 9) Melhor Padaria de São
              Paulo. Na Fase 2: 10) Melhor Padaria da Zona Norte; 11) Melhor
              Padaria da Zona Leste; 12) Melhor Padaria da Zona Norte; 13)
              Melhor Padaria da Zona Sul; 14) Melhor Padaria da Região Central;
              15) Destaque de Padaria Sustentável; 16) Padaria vencedora do
              Desafio Hellmann’s de melhor bauru de São Paulo; 17) Melhor Pão na
              Chapa com Catupiry de São Paulo.
            </p>
            <br />
            <p>
              Art. 2º – Serão concedidos diplomas de finalistas nas categorias
              da Fase 2: 1) Padaria da Zona Norte; 2) Padaria da Zona Leste; 3)
              Padaria da Zona Sul; 4) Padaria da Zona Oeste; 5) Padaria da
              Região Central.
            </p>
            <br />
            <p>
              Art. 3º – Serão concedidos menção e troféu às padarias que
              alcançarem o 2º e o 3º lugares na votação das categorias da Fase 1
              contidas neste regulamento.
            </p>
            <br />
            <p>
              Art. 4º – Será enviado por e-mail certificado de participação
              digital a todas as padarias que receberam pelo menos uma indicação
              na Fase 1, desde que se consiga obter um e-mail para contato e
              envio do mesmo.
            </p>
            <br />
            <p>CAPÍTULO V – DA DIVULGAÇÃO DO RESULTADO E ENTREGA DOS PRÊMIOS</p>
            <br />
            <p>
              Art. 1° – Os resultados serão divulgados no dia 7 de novembro de
              2024 na plataforma{' '}
              <a
                href="https://www.padocariasp.com.br"
                target="_blank"
                rel="noreferrer"
                style={{ color: '#40b2f5', textDecoration: 'underline' }}
              >
                www.padocariasp.com.br
              </a>{' '}
              e redes sociais do Padocaria-SP.
            </p>
            <br />
            <p>
              Art. 2° – No dia 7 de novembro de 2024 acontecerá o evento de
              premiação, com entrega dos troféus e certificados aos finalistas e
              vencedores de cada categoria.
            </p>
            <br />
            <p>CAPÍTULO VI – DAS CONISIDERAÇÕES FINAIS</p>
            <br />
            <p>
              Art. 1° – Ao participarem do evento de premiação, os presentes
              autorizam automaticamente o Padocaria-SP a utilizar, editar,
              publicar, reproduzir por meio impresso, digital, redes sociais e
              outros meios de comunicação visual: imagens, conteúdos, enfim,
              toda e qualquer informação do participante relacionada ao prêmio.
            </p>
            <br />
            <p>
              Art. 2° – Os resultados decorrentes da premiação e divulgados pelo
              Padocaria-SP são inquestionáveis e irrecorríveis.
            </p>
            <br />
            <p>
              Art. 3° – Os vencedores poderão utilizar, para fins de divulgação,
              o prêmio Padocaria-SP em seu estabelecimento e em todos os seus
              materiais e redes sociais. A logomarca do Padocaria-SP não poderá,
              em nenhuma circunstância, ter o seu conteúdo, cor ou formato
              alterados. Para a utilização de qualquer outra logomarca, nome ou
              material de propriedade do Padocaria-SP, será necessária
              autorização.
            </p>
            <br />
            <p>
              Art. 4° – A participação no Padocaria-SP implica a aceitação
              irrestrita deste regulamento.
            </p>
            <br />
          </div>
          <div className="actions">
            <button type="button" onClick={back}>
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Regulamento;
