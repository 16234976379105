/* eslint-disable no-unused-vars */
import React from 'react';
// import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { isMobile } from 'react-device-detect';
import L from 'leaflet';
import { useGlobal } from '../contexts/global';

import 'leaflet/dist/leaflet.css';
import '../assets/scss/components/Mapa.scss';

import MarkerIcon from '../assets/images/marker-icon.png';

const icon = L.icon({ iconUrl: MarkerIcon });

const containerStyle = {
  width: '100%',
  height: '100vh'
};

const Mapa = () => {
  const { center, setMap, selectedLocation, zoom } = useGlobal();

  // const { isLoaded } = useJsApiLoader({
  //   id: 'google-map-script',
  //   googleMapsApiKey: 'AIzaSyAQFWnvXGDI6A5SDZg2YdqxTdSXIb2BdhA'
  // });

  // const onLoad = React.useCallback(function callback(mapNew) {
  //   const bounds = new window.google.maps.LatLngBounds();
  //   map?.fitBounds(bounds);
  //   setMap(mapNew);
  // }, []);

  // const onUnmount = React.useCallback(function callback() {
  //   setMap(null);
  // }, []);

  function ChangeView() {
    const map = useMap();
    map.setView([center.lat, center.lng], zoom);
    return null;
  }

  return (
    <div className="in-map">
      {/* {isLoaded && (
        <GoogleMap
          zoom={zoom}
          center={center}
          onLoad={onLoad}
          onUnmount={onUnmount}
          mapContainerStyle={containerStyle}
        >
          {selectedLocation.localizacao?.latitude &&
            selectedLocation.localizacao?.longitude && (
              <Marker
                position={{
                  lat: selectedLocation.localizacao.latitude,
                  lng: selectedLocation.localizacao.longitude
                }}
                animation={window.google.maps.Animation.DROP}
              />
            )}
        </GoogleMap> */}
      <MapContainer
        center={[center.lat, center.lng]}
        zoom={zoom}
        dragging={!isMobile}
        style={{ height: '100vh', width: '100%' }}
      >
        <ChangeView />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {selectedLocation.localizacao?.latitude &&
          selectedLocation.localizacao?.longitude && (
            <Marker
              icon={icon}
              position={[
                selectedLocation.localizacao.latitude,
                selectedLocation.localizacao.longitude
              ]}
            >
              <Popup>
                <strong style={{ fontSize: '1.1rem' }}>
                  {selectedLocation.nome}
                </strong>{' '}
                <br /> {selectedLocation.endereco_completo}
              </Popup>
            </Marker>
          )}
      </MapContainer>
      {/* )} */}
    </div>
  );
};

export default Mapa;
