import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';

const GlobalContext = createContext({});

export const GlobalProvider = ({ children }) => {
  const [center, setCenter] = useState({
    lat: -23.554438,
    lng: -46.649014
  });
  const [zoom, setZoom] = useState(16);
  const [local, setLocal] = useState('');
  const [map, setMap] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [showRegulamento, setShowRegulamento] = useState(false);
  const [showModalVotacao, setShowModalVotacao] = useState(false);
  const [idPadaria, setIdPadaria] = useState('');
  const [nomePadaria, setNomePadaria] = useState('');
  const [zonaAtual, setZonaAtual] = useState('');

  return (
    <GlobalContext.Provider
      value={{
        center,
        setCenter,
        local,
        setLocal,
        map,
        setMap,
        selectedLocation,
        setSelectedLocation,
        zoom,
        setZoom,
        success,
        setSuccess,
        showRegulamento,
        setShowRegulamento,
        error,
        setError,
        showModalVotacao,
        setShowModalVotacao,
        idPadaria,
        setIdPadaria,
        nomePadaria,
        setNomePadaria,
        zonaAtual,
        setZonaAtual
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

GlobalProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useGlobal = () => useContext(GlobalContext);

export default GlobalContext;
