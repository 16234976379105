import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import {
  buscaLocais,
  buscaZonas,
  buscaBairros,
  votacao
} from '../services/Votacao';

import { useGlobal } from '../contexts/global';
import '../assets/scss/components/VoteForm.scss';

import bgVoteform from '../assets/images/bg_voteform.png';
import iconSearch from '../assets/images/icon_search.png';
import iconLocation from '../assets/images/icon_location.png';
import iconGoogle from '../assets/images/icon_google.png';
import imageChapeiro from '../assets/images/image_chapeiro.png';
import imagePaoNaChapa from '../assets/images/image_paonachapa.png';
import imageDoce from '../assets/images/image_doce.jpg';
import imagePaozinho from '../assets/images/image_paozinho.png';
import imagePizza from '../assets/images/image_pizza.png';
import imageFrios from '../assets/images/image_frios.png';
import imageCafezinho from '../assets/images/image_cafezinho.png';
import imagePadaria from '../assets/images/image_padaria.png';
import imageFermentacaoNatural from '../assets/images/image_fermentacao_natural.jpg';

let timeout;
const temas = {
  pizza: {
    id: 1,
    nome: 'Pizza',
    image: imagePizza
  },
  melhor_time_de_chapeiros: {
    id: 2,
    nome: 'Melhor time de chapeiros',
    image: imageChapeiro
  },
  servico_de_frios: {
    id: 3,
    nome: 'Serviço de frios',
    image: imageFrios
  },
  doce: {
    id: 4,
    nome: 'Sonho',
    image: imageDoce
  },
  cafezinho: {
    id: 5,
    nome: 'Café',
    image: imageCafezinho
  },
  paozinho: {
    id: 6,
    nome: 'Pãozinho',
    image: imagePaozinho
  },
  pao_na_chapa: {
    id: 7,
    nome: 'Pão na chapa',
    image: imagePaoNaChapa
  },
  melhor_padaria: {
    id: 8,
    nome: 'Melhor padaria',
    image: imagePadaria
  },
  melhor_pao_de_fermentacao_natural: {
    id: 9,
    nome: 'Melhor Pão de Fermentação Natural',
    image: imageFermentacaoNatural
  }
};

const VoteForm = () => {
  const [step, setStep] = useState(1);
  const [inputLocal, setInputLocal] = useState('');
  const [showCadastroPadaria, setShowCadastroPadaria] = useState(false);
  const [isLoading, setIsLoadinng] = useState(false);
  const [checkRegulamento1, setCheckRegulamento1] = useState(false);
  const [checkRegulamento2, setCheckRegulamento2] = useState(false);
  const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);
  const [resultadosBusca, setResultadosBusca] = useState([]);
  const [nomePadaria, setNomePadaria] = useState('');
  const [email, setEmail] = useState('');
  const [zona, setZona] = useState('');
  const [bairro, setBairro] = useState('');
  const [zonasOptions, setZonasOptions] = useState([]);
  const [bairrosOptions, setBairrosOptions] = useState([]);

  const {
    selectedLocation,
    setSelectedLocation,
    setCenter,
    setZoom,
    setShowRegulamento
  } = useGlobal();

  const history = useHistory();
  const { tema } = useParams();

  const handleShowCadastroPadaria = () => {
    setInputLocal('');
    setSelectedLocation('');
    setShowCadastroPadaria(true);
  };

  const hideCadastroPadocaria = () => {
    setNomePadaria('');
    setZona('');
    setBairro('');
    setShowCadastroPadaria(false);
  };

  const handleSelectLocation = (local) => {
    hideCadastroPadocaria();
    setInputLocal(local.nome);

    if (
      local.localizacao.latitude === null ||
      local.localizacao.longitude === null
    ) {
      setCenter({
        lat: -23.554438,
        lng: -46.649014
      });
    } else {
      setCenter({
        lat: local.localizacao.latitude,
        lng: local.localizacao.longitude
      });
    }
    setZoom(18);
    setSelectedLocation(local);

    if (local.bairro_id !== null) {
      setBairro(local.bairro_id);
      setZona(local.zona_id);
    }

    setResultadosBusca([]);
  };

  const handleAutocomplete = (e) => {
    setSelectedLocation('');
    setInputLocal(e.target.value);
    if (e.target.value.length >= 3) {
      setLoadingAutocomplete(true);
      clearTimeout(timeout);
      timeout = setTimeout(async () => {
        const resp = await buscaLocais(e.target.value);
        setResultadosBusca(resp.data);
        setLoadingAutocomplete(false);
      }, 500);
    } else if (e.target.value.length < 3) {
      setResultadosBusca([]);
      setLoadingAutocomplete(false);
    }
  };

  const handleSelectZona = async (e) => {
    setZona(e.target.value);

    const resp = await buscaBairros(e.target.value);
    setBairrosOptions(resp);
  };

  const handleVotacao = () => {
    setIsLoadinng(true);
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_KEY_RECAPTCHA, {
          action: 'submit'
        })
        .then(async () => {
          const data = {
            email: email || '',
            tema_id: temas[tema.replaceAll('-', '_')].id,
            zona_id: zona || '',
            bairro_id: bairro || '',
            endereco_completo: selectedLocation
              ? selectedLocation.endereco_completo
              : '',
            latitude: selectedLocation
              ? selectedLocation.localizacao.latitude
              : '',
            longitude: selectedLocation
              ? selectedLocation.localizacao.longitude
              : '',
            estabelecimento: selectedLocation
              ? selectedLocation.nome
              : nomePadaria,
            place_id: selectedLocation ? selectedLocation.place_id : ''
          };

          try {
            await votacao(data);
            localStorage.setItem('current_vote', tema);

            let votes = JSON.parse(localStorage.getItem(email));

            if (votes !== null) {
              localStorage.setItem(
                email,
                JSON.stringify([...votes, temas[tema.replaceAll('-', '_')].id])
              );
            } else {
              localStorage.setItem(
                email,
                JSON.stringify([temas[tema.replaceAll('-', '_')].id])
              );
            }

            votes = JSON.parse(localStorage.getItem(email));
            let urlRedirect = '/';
            let textRedirect =
              'Em alguns instantes você receberá um e-mail para confirmar seu voto';
            let buttonText = 'Voltar para home';

            const idNextTema = [...Array(9).keys()]
              .map((i) => i + 1)
              .find((i) => !votes.includes(i));

            if (votes.length < 9) {
              const temaEncontrado = Object.entries(temas).find(
                ([key, t]) => t.id === idNextTema && key != null
              );

              if (temaEncontrado) {
                const [key, t] = temaEncontrado;
                buttonText = t.nome;
                urlRedirect = key;
                textRedirect =
                  'Você receberá um e-mail para confirmar seu voto em breve. Enquanto isso, sinta-se à vontade para votar também na categoria:';
              }
            }

            if (votes.length === 9) {
              localStorage.removeItem(email);
              textRedirect =
                'Obrigado por votar em todas as categorias! Sua participação é muito importante para nós.';
              buttonText = 'Voltar para home';
              urlRedirect = '/';
            }

            Swal.fire({
              icon: 'warning',
              text: textRedirect,
              confirmButtonText: buttonText
            }).then(() => {
              setStep(1);
              setInputLocal('');
              setSelectedLocation('');
              setCheckRegulamento1(false);
              history.push(urlRedirect);
            });
          } catch (error) {
            if (error.response.status === 422) {
              const votes = JSON.parse(localStorage.getItem(email));

              if (votes !== null) {
                localStorage.setItem(
                  email,
                  JSON.stringify([
                    ...votes,
                    temas[tema.replaceAll('-', '_')].id
                  ])
                );
              } else {
                localStorage.setItem(
                  email,
                  JSON.stringify([temas[tema.replaceAll('-', '_')].id])
                );
              }
              Swal.fire({
                timer: 2500,
                icon: 'error',
                showConfirmButton: false,
                text: 'Usuário já votou nessa categoria!'
              });
            }
          }
        });
    });
    setIsLoadinng(false);
  };

  const handleClickSSO = (social) => {
    const data = {
      email: email || '',
      tema_id: temas[tema.replaceAll('-', '_')].id,
      zona_id: zona || '',
      bairro_id: bairro || '',
      endereco_completo: selectedLocation
        ? selectedLocation.endereco_completo
        : '',
      latitude: selectedLocation ? selectedLocation.localizacao.latitude : '',
      longitude: selectedLocation ? selectedLocation.localizacao.longitude : '',
      estabelecimento: selectedLocation ? selectedLocation.nome : nomePadaria,
      place_id: selectedLocation ? selectedLocation.place_id : ''
    };

    localStorage.setItem('vote_data', JSON.stringify(data));
    localStorage.setItem('current_vote', tema);

    if (social === 'google') {
      window.location.href = `${process.env.REACT_APP_API_URL}auth/login/google`;
    } else if (social === 'facebook') {
      window.location.href = `${process.env.REACT_APP_API_URL}auth/login/facebook`;
    }
  };

  useEffect(() => {
    let unmounted = false;
    const loadZonas = async () => {
      const resp = await buscaZonas();
      if (!unmounted) {
        setZonasOptions(resp);
      }
    };
    loadZonas();
    setSelectedLocation('');
    setCenter({
      lat: -23.554438,
      lng: -46.649014
    });
    setZoom(16);
    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.id = id;
        script.onload = () => {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      'recaptcha-key',
      `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_KEY_RECAPTCHA}`,
      () => {}
    );
  }, []);

  return (
    <div className="in-voteform">
      <div className="in-voteform-bg">
        <img src={bgVoteform} alt={bgVoteform} />
      </div>
      <div className="in-voteform-grid">
        <div className="in-voteform-grid-sup">
          <div className="in-voteform-grid-sup-lf">
            <img
              src={temas[tema.replaceAll('-', '_')].image}
              alt={temas[tema.replaceAll('-', '_')].image}
            />
          </div>
          <div className="in-voteform-grid-sup-rt">
            <div className="in-voteform-grid-sup-rt-ct">
              <p>Votar na categoria</p>
              <p>{temas[tema.replaceAll('-', '_')].nome}</p>
            </div>
          </div>
        </div>
        <div className="in-voteform-grid-sub">
          {step === 1 && (
            <>
              {/* Área de busca de padarias */}
              <div className="in-voteform-grid-sub-search">
                <div className="in-voteform-grid-sub-search-input">
                  <input
                    type="text"
                    value={inputLocal}
                    onChange={handleAutocomplete}
                    placeholder="Pesquise"
                  />
                  {loadingAutocomplete ? (
                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                  ) : (
                    <img src={iconSearch} alt={iconSearch} />
                  )}
                </div>
                <div id="checkbox-wrapper-1" style={{ padding: '5px' }}>
                  <input
                    type="checkbox"
                    name="chk-regulamento-1"
                    id="chk-regulamento-1"
                    checked={checkRegulamento1}
                    onChange={() => setCheckRegulamento1(!checkRegulamento1)}
                  />{' '}
                  <label>
                    Li e concordo com o{' '}
                    <span
                      style={{
                        textDecoration: 'underline',
                        cursor: 'pointer'
                      }}
                      onClick={() => setShowRegulamento(true)}
                    >
                      regulamento
                    </span>
                  </label>
                </div>
                <div className="in-voteform-grid-sub-search-btn">
                  <button
                    disabled={selectedLocation === '' || !checkRegulamento1}
                    onClick={() => setStep(2)}
                    type="button"
                  >
                    Confirmar
                  </button>
                </div>
                <div className="in-voteform-grid-sub-search-ancor">
                  <p>
                    Caso não encontre a padaria,{' '}
                    <span onClick={handleShowCadastroPadaria}>clique aqui</span>
                  </p>
                </div>

                {/* Resultados do autocomplete */}
                {resultadosBusca.length > 0 && (
                  <div className="in-voteform-grid-sub-search-results">
                    <div className="in-voteform-grid-sub-search-results-scrollbar">
                      <ul>
                        {resultadosBusca.map((item) => (
                          <li
                            key={item.key}
                            onClick={() => handleSelectLocation(item)}
                          >
                            <img src={iconLocation} alt={iconLocation} />
                            <span>
                              {item.nome}
                              {item.endereco_completo ? <br /> : null}
                              {item.endereco_completo ? (
                                <small>{`${item.endereco_completo}`}</small>
                              ) : null}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
                {resultadosBusca.length < 1 &&
                  inputLocal.length >= 3 &&
                  !loadingAutocomplete &&
                  !selectedLocation && (
                    <div className="in-voteform-grid-sub-search-results">
                      <div className="in-voteform-grid-sub-search-results-scrollbar">
                        <ul>
                          <li className="no-result">
                            <span>Nenhum resultado encontrado!</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
              </div>

              {/* Cadastro de Padaria */}
              {showCadastroPadaria && (
                <div className="in-voteform-grid-sub-register">
                  <div className="in-voteform-grid-sub-register-title">
                    <p>Cadastrar padaria</p>
                  </div>
                  <div className="in-voteform-grid-sub-register-form">
                    <input
                      type="text"
                      id="nome-padaria"
                      name="nome-padaria"
                      placeholder="Digite o nome da padaria"
                      value={nomePadaria}
                      onChange={(e) => setNomePadaria(e.target.value)}
                    />
                    <select
                      value={zona}
                      id="slt-zonas"
                      name="slt-zonas"
                      onChange={handleSelectZona}
                    >
                      <option value="">Zona</option>
                      {zonasOptions.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.zona}
                        </option>
                      ))}
                    </select>
                    <select
                      value={bairro}
                      id="slt-bairros"
                      className="input"
                      name="slt-bairros"
                      onChange={(e) => setBairro(e.target.value)}
                    >
                      {bairrosOptions.length > 0 ? (
                        <option value="">Selecione o bairro...</option>
                      ) : (
                        <option value="">Selecione uma zona acima</option>
                      )}
                      {bairrosOptions.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.bairro}
                        </option>
                      ))}
                    </select>
                    <div id="checkbox-wrapper-2" style={{ padding: '5px' }}>
                      <input
                        type="checkbox"
                        name="chk-regulamento-2"
                        id="chk-regulamento-2"
                        checked={checkRegulamento2}
                        onChange={() =>
                          setCheckRegulamento2(!checkRegulamento2)
                        }
                      />{' '}
                      <label>
                        Li e concordo com o{' '}
                        <span
                          style={{
                            textDecoration: 'underline',
                            cursor: 'pointer'
                          }}
                          onClick={() => setShowRegulamento(true)}
                        >
                          regulamento
                        </span>
                      </label>
                    </div>
                    <button
                      disabled={
                        nomePadaria === '' ||
                        zona === '' ||
                        bairro === '' ||
                        !checkRegulamento2
                      }
                      type="button"
                      onClick={() => setStep(2)}
                    >
                      Cadastrar
                    </button>
                  </div>
                  <div className="in-voteform-grid-sub-register-back">
                    <span onClick={hideCadastroPadocaria}>Voltar</span>
                  </div>
                </div>
              )}
            </>
          )}
          {step === 2 && (
            <>
              <div className="in-voteform-grid-sub-confirm">
                <div className="in-voteform-grid-sub-confirm-title">
                  <p>Confirmar voto em:</p>
                  <p>{selectedLocation ? inputLocal : nomePadaria}</p>
                  <p>
                    Para finalizar o voto precisamos fazer uma confirmação por
                    e-mail
                  </p>
                </div>
                <div className="in-voteform-grid-sub-confirm-email">
                  <div className="in-voteform-grid-sub-confirm-email-input">
                    <input
                      id="email"
                      type="email"
                      name="email"
                      value={email}
                      placeholder="Digite o seu e-mail"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <button
                      disabled={email === '' || isLoading}
                      type="button"
                      onClick={() => {
                        setIsLoadinng(true);
                        setTimeout(() => {
                          handleVotacao();
                        }, 2000);
                      }}
                    >
                      {isLoading ? (
                        <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                      ) : (
                        'Confirmar'
                      )}
                    </button>
                    <span onClick={() => setStep(1)}>Voltar</span>
                  </div>
                  <div className="in-voteform-grid-sub-confirm-email-or">
                    <div />
                    <span>ou</span>
                    <div />
                  </div>
                  <div className="in-voteform-grid-sub-confirm-email-btn">
                    <button
                      type="button"
                      onClick={() => handleClickSSO('google')}
                    >
                      <img src={iconGoogle} alt={iconGoogle} />
                      <span>Acessar com o google</span>
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default VoteForm;
